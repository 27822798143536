import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem
} from 'components/ui/sidebar';
import { Collapsible, CollapsibleTrigger } from 'components/ui/collapsible';
import { Link } from 'react-router-dom';
import { Flags } from '../contexts/FlagProvider';
import { features } from '../constants/index';

export function NavProjects({ projects }) {
  return (
    <SidebarGroup className="text-[#ffffffc2]">
      <SidebarMenu>
        {projects.map((item) => (
          item.isHasAFlag ? ( 
            <Flags
              key={item.name}
              authorizedFlags={[features[item.id]]}
              renderOn={() => (
                <SidebarMenuItem>
                  <Collapsible
                    key={item.title}
                    asChild
                    defaultOpen={item.isActive}
                    className="group/collapsible"
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton tooltip={item.name} asChild>
                          <Link to={item.url}>
                            {item.icon && <item.icon />}
                            <span>{item.name}</span>
                          </Link>
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                    </SidebarMenuItem>
                  </Collapsible>
                </SidebarMenuItem>
              )}
              renderOff={() => (
                <SidebarMenuItem>
                  <SidebarMenuButton tooltip={item.name} asChild>
                    <Link to="/usage">
                      {item.icon && <item.icon />}
                      <span>{item.name} (Upgrade)</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
            />
          ) : (
            <SidebarMenuItem key={item.name}>
              <Collapsible
                key={item.title}
                asChild
                defaultOpen={item.isActive}
                className="group/collapsible"
              >
                <SidebarMenuItem>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton tooltip={item.name} asChild>
                      <Link to={item.url}>
                        {item.icon && <item.icon />}
                        <span>{item.name}</span>
                      </Link>
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                </SidebarMenuItem>
              </Collapsible>
            </SidebarMenuItem>
          )
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
