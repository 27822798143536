import PropTypes from 'prop-types';

const ProgressBar = ({ 
  label, 
  currentUsage, 
  limit, 
  showCurrentMarker = true 
}) => {
  const rawPercentage = (currentUsage / limit) * 100 || 0;
  const percentage = Math.min(100, rawPercentage);
  const markerPercentage = Math.min(percentage, 95);

  const getGradientColor = (pct) => {
    if (pct < 75) {
      return 'bg-gradient-to-r from-[#e5d9cc] to-[#d0c0ae]';
    }
    return 'bg-gradient-to-r from-[#d0c0ae] to-[#ff8b00]';
  };

  return (
    <div className="relative pb-12 overflow-x-hidden"> 
      <div>
        <h4 className="sr-only">Usage Status</h4>
        <p className="text-sm font-medium text-gray-900">{label}</p>
      </div>

      <div className="flex items-center gap-2.5 mt-6">
        <div aria-hidden="true" className="w-full">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              style={{ width: `${percentage}%` }}
              className={`h-2 rounded-full transition-all duration-300 ${getGradientColor(percentage)}`}
            />
          </div>
        </div>
      </div>

      {showCurrentMarker && (
        <div
          className="absolute flex flex-col -mt-2 ml-2 border-l pt-3.5 px-1.5"
          style={{
            left: `${markerPercentage}%`,
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap', 
          }}
        >
          <span className="text-xs font-medium text-gray-700">
            {currentUsage}
          </span>
        </div>
      )}

      <div className="absolute right-0 top-6">
        <span className="text-sm text-gray-600">{limit}</span>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  label: PropTypes.string.isRequired,
  currentUsage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  showCurrentMarker: PropTypes.bool,
};

export default ProgressBar;
