import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLoaderData, useNavigate } from 'react-router-dom';
import { loginViewStates } from 'constants/index';
import LoginInputEmail from 'features/login/LoginInputEmail';
import LoginFooter from 'features/login/LoginFooter';
import Logo from 'components/logo/Logo';
import { logUserIn } from '../network';
import { useDispatch } from 'react-redux';
import { setLoginState, setStripeInfo, setUserRole } from 'redux/slices/userSlice';
import awslogo from '../../../assets/img/aws-white-logo.png';
import slacklogo from '../../../assets/img/slack-logo-white.webp';
import chatgptlogo from '../../../assets/img/chatgpt-logo.png';
import jiralogo from '../../../assets/img/jira-logo.png';
import mysqllogo from '../../../assets/img/mysql-white-logo.png';
import webhooklogo from '../../../assets/img/webhook-white-logo.png';
import zapierlogo from '../../../assets/img/zapier-logo-white.png';
import sshlogo from '../../../assets/img/ssh-white.png';
import lightLogo from '../../../assets/img/opsbeacon-light-logo.png';
import LoginWorkspaceSettings from '../LoginActions/index';
import { useToast } from 'hooks/use-toast';

const LoginMain = () => {
  const [viewState, setViewState] = useState(loginViewStates.DEFAULT);
  const [email, setEmail] = useState('');
  const [workspaceSettings, setWorkspaceSettings] = useState(false);
  const [workspaceOptions, setWorkspaceOptions] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slackCode, setSlackCode] = useState('');
  const { success, payload, successStripeInfo, payloadStripeInfo } = useLoaderData();
  const { toast } = useToast();

  useEffect(() => {
    if (!success && !successStripeInfo) {
      setViewState(loginViewStates.DEFAULT);
    }
    if (success && successStripeInfo) {
      dispatch(setStripeInfo(payloadStripeInfo));
      dispatch(setUserRole(payload));

      if (payloadStripeInfo.status === 'inactive') {
        navigate('/usage');
      } else {
        setViewState(loginViewStates.REDIRECT_TO_DASHBOARD);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const onFormSubmit = (evt) => {
    evt.preventDefault();

    logUserIn(email)
      .then(({ success, result }) => {
        if (success) {
          setWorkspaceSettings(true);
          setWorkspaceOptions(result.settings);
        } else {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description:'Please check your email'
          });
          dispatch(setLoginState(false));
          setViewState(loginViewStates.DEFAULT);
        }
      })
      .catch(() => {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description:'Please check your email'
        });
        dispatch(setLoginState(false));
        setViewState(loginViewStates.DEFAULT);
      });
  };

  useEffect(() => {
    const getSlackAccessToken = async () => {
      const code = new URLSearchParams(window.location.search).get('code');
      setSlackCode(code);
    };
    getSlackAccessToken();
  }, [slackCode]);
  
  if (viewState === loginViewStates.REDIRECT_TO_DASHBOARD) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="flex min-h-full  h-screen w-screen flex-col bg-secondary sm:supports-[min-height:100dvh]:min-h-[100dvh] md:grid md:grid-cols-2 lg:grid-cols-[60%_40%]">
      <div className="relative hidden flex-1 flex-col justify-center px-5 pt-8 text-gray-400 md:flex md:px-6 md:py-[22px] lg:px-8">
        <nav className="left-0 top-8 flex w-full px-6 sm:absolute md:top-[22px] md:px-6 lg:px-8">
          <h1 aria-label="ChatGPT by OpenAI">
            <div className="flex cursor-default items-center text-[20px] font-bold leading-none lg:text-[22px]">
              <div>
                <Logo />
              </div>
            </div>
          </h1>
        </nav>
        <div className="flex flex-col text-[32px] leading-[1.2] md:text-[40px]" aria-hidden="true">
          <div className="absolute left-0 top-1/2 flex w-full translate-y-[calc(-50%-1em)] flex-col px-5 opacity-100 transition-[transform,opacity] delay-[500ms] duration-500 md:pl-6 md:pr-8 lg:pl-8 lg:pr-10">
            <div className="relative font-bold">Secure. Efficient.</div>
            <div className="relative">
              <div className="absolute left-0 top-0 max-w-[650px] transition-opacity duration-300">
                <span className="inline transition-opacity duration-300">Compliant.</span>
                <span className="font-circle inline-block scale-0 transition-transform delay-[300ms] duration-300">
                  <span>&hairsp;</span>●
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          x-data="{}"
          x-init="$nextTick(() => {
        let ul = $refs.logos;
        ul.insertAdjacentHTML('afterend', ul.outerHTML);
        ul.nextSibling.setAttribute('aria-hidden', 'true');
    })"
          className="absolute bottom-20 inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
        >
          <ul
            x-ref="logos"
            className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_img]:max-w-none [&_li]:mx-8"
          >
            <li>
              <img src={awslogo} className="h-12" alt="aws logo" />
            </li>
            <li>
              <img src={slacklogo} className="h-12" alt="slack logo" />
            </li>
            <li>
              <img src={chatgptlogo} className="h-16" alt="chatgpt logo" />
            </li>
            <li>
              <img src={jiralogo} className="h-12" alt="jira logo" />
            </li>
            <li>
              <img src={mysqllogo} className="h-16" alt="mysql logo" />
            </li>
            <li>
              <img src={webhooklogo} className="h-12" alt="webhook logo" />
            </li>
            <li>
              <img src={zapierlogo} className="h-12" alt="zapier logo" />
            </li>
            <li>
              <img src={sshlogo} className="h-12" alt="ssh logo" />
            </li>
            <li>
              <img src={awslogo} className="h-12" alt="aws logo" />
            </li>
            <li>
              <img src={slacklogo} className="h-12" alt="slack logo" />
            </li>
            <li>
              <img src={chatgptlogo} className="h-16" alt="chatgpt logo" />
            </li>
            <li>
              <img src={jiralogo} className="h-12" alt="jira logo" />
            </li>
            <li>
              <img src={mysqllogo} className="h-16" alt="mysql logo" />
            </li>
            <li>
              <img src={webhooklogo} className="h-12" alt="webhook logo" />
            </li>
            <li>
              <img src={zapierlogo} className="h-12" alt="zapier logo" />
            </li>
            <li>
              <img src={sshlogo} className="h-12" alt="ssh logo" />
            </li>
          </ul>
        </div>
      </div>
      {workspaceSettings || slackCode ? (
        <LoginWorkspaceSettings
          email={email}
          workspaceOptions={workspaceOptions}
          slackCode={slackCode}
        />
      ) : (
        <div className="relative flex grow flex-col items-center justify-between bg-gray-300 px-5 py-8 text-black dark:bg-black dark:text-white sm:rounded-t-[30px] md:rounded-none md:px-6">
          <nav className="flex w-full justify-start pb-8 pl-0 md:hidden md:px-6 lg:px-8">
            <h1 aria-label="ChatGPT by OpenAI">
              <div className="flex cursor-default items-center text-[20px] font-bold leading-none lg:text-[22px]">
                <div className="hidden lg:block">
                  <Logo />
                </div>
                <div className="block lg:hidden">
                  <img src={lightLogo} className="h-12" alt="opsbeacon logo" />
                </div>
              </div>
            </h1>
          </nav>
          <div className="relative flex w-full grow flex-col items-center justify-center">
            <div className="w-full max-w-[440px]">
              <div className="flex flex-1 flex-col justify-center lg:flex-none">
                <div>
                  <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                  </h2>
                </div>

                <div className="mt-10">
                  <div>
                    <form onSubmit={onFormSubmit}>
                      <LoginInputEmail value={email} onChange={onEmailChange} />
                      <div className="flex items-center justify-end">
                        <div className="-mb-2 mt-4 text-sm leading-6">
                          <Link
                            to="/forgotpassword"
                            className="font-semibold text-gray-900 hover:text-gray-500"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="mt-6">
                        <span className="block w-full rounded-md shadow-sm">
                          <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-secondary px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            Continue
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                  <div className="mt-10">
                    <div className="mt-8">
                      <label
                        htmlFor="acceptPolicy"
                        className="mt-2 cursor-pointer text-center text-sm text-gray-600"
                      >
                        By continuing, you agree to the{' '}
                        <Link
                          to="https://docs.ob2.ai/legal/terms-of-service"
                          target="_blank"
                          className="font-black text-gray-900 underline"
                        >
                          Terms of Service
                        </Link>{' '}
                        and
                        <Link
                          to="https://docs.ob2.ai/legal/privacy"
                          target="_blank"
                          className="font-black text-gray-900 underline"
                        >
                          {' '}
                          Privacy Policy
                        </Link>{' '}
                      </label>
                    </div>
                    <LoginFooter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginMain;
