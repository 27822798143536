import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStripeInfo, setUserRole } from '../redux/slices/userSlice';
import { fetchUsers } from 'features/settings/Groups/GroupNew/io';
import { useAuditLogListFetch } from '../hooks/useAuditLogListFetch'; 

export const useUsageData = (loaderData) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  const formattedStartDate = startDate.toISOString().slice(0, 10).replace(/-/g, '');

  const endDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');

  const { data, loading } = useAuditLogListFetch({
    initialStartDate: formattedStartDate,
    initialEndDate: endDate, 
    initialPage: 1,
    initialLimit: 10,
    initialOrderBy: 'timestamp',
    initialDirection: 'desc'
  });

  useEffect(() => {
    if (data !== null) {
      setTableData(data.logs);
      setDataCount(data.dataCount);
      setTotalPages(Math.ceil(data.dataCount / 10));
    }
  }, [data]);

  useEffect(() => {
    if (loaderData.success) {
      dispatch(setUserRole(loaderData.payload));
    }
    if (loaderData.successStripeInfo) {
      dispatch(setStripeInfo(loaderData.payloadStripeInfo));
    }
    fetchUsers().then(({ success, payload }) => {
      const { users } = payload;
      setUsers(users.length);
    });
  }, [dispatch, loaderData]);

  return { 
    users,
    auditLogData: {
      tableData,
      dataCount,
      totalPages,
      loading
    }
  };
};