import { useEffect, useState } from "react";
import { fetchWorkspaceSettingsData, updateWorkspaceSettings } from "./io";
import { useToast } from "hooks/use-toast";

export default function WorkspaceSettings() {
  const [workspaceSettings, setWorkspaceSettings] = useState([
    {
      id: "enableGoogleLogin",
      name: "Google Authentication",
      description: "Turns on Google Authentication",
      active: false,
    },
    {
      id: "enableSlackLogin",
      name: "Slack Authentication",
      description: "Turns on Slack Authentication",
      active: false,
    },
    {
      id: "enableMicrosoftLogin",
      name: "Microsoft Authentication",
      description: "Turns on Microsoft Authentication",
      active: false,
    },
    {
      id: "enablePasswordLogin",
      name: "Password Authentication",
      description: "Enable Password Login",
      active: false,
    },
  ]);
  const { toast } = useToast();

  useEffect(() => {
    fetchWorkspaceSettingsData()
      .then(({ success, payload }) => {
        if (!success) {
          return;
        }
        const { settings } = payload;
        const fetchedWorkspaceSettings = settings.workspaceLoginSettings;

        setWorkspaceSettings((prevState) =>
          prevState.map((setting) => ({
            ...setting,
            active: fetchedWorkspaceSettings[setting.id] || false,
          }))
        );
      })
      .catch(() => {});
  }, []);

  const handleWorkspaceSettings = (settingsId) => {
    setWorkspaceSettings((prevState) =>
      prevState.map((setting) =>
        setting.id === settingsId
          ? { ...setting, active: !setting.active }
          : setting
      )
    );
  };

  const saveWorkspaceSettings = () => {
    const transformedSettings = workspaceSettings.reduce((acc, setting) => {
      acc[setting.id] = setting.active;
      return acc;
    }, {});

    updateWorkspaceSettings({ workspaceSettings: transformedSettings })
      .then(() => {
        toast({
          title: "Successfully",
          description: "Workspace settings updated successfully",
        });
      })
      .catch(() => {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "Workspace settings updated failed",
        });
      });
  };

  return (
    <div className="form-container">
      <div className="hero-section">
        <div className="bg-white p-6 shadow dark:bg-slate-900 sm:rounded-lg sm:p-8 ">
          <div className="w-full">
            <h2 className="my-8 mt-0 text-center text-base font-bold">
              WORKSPACE SETTINGS
            </h2>
            {workspaceSettings.map((auth, index) => (
              <div
                key={auth.id}
                className="mb-10 flex items-center justify-between gap-x-2"
              >
                <div>
                  <p className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    {auth.name}
                  </p>
                  <p className="font-bold text-gray-500">{auth.description}</p>
                </div>
                <label className="relative inline-flex cursor-pointer items-center">
                  <input
                    id={`switch-${index}`}
                    type="checkbox"
                    className="peer sr-only"
                    defaultChecked={auth.active}
                    onChange={() => handleWorkspaceSettings(auth.id)}
                    checked={auth.active}
                  />
                  <label htmlFor={`switch-${index}`} className="hidden"></label>
                  <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300"></div>
                </label>
              </div>
            ))}
            <div className="flex items-center justify-center">
              <button
                onClick={saveWorkspaceSettings}
                type="button"
                className="btn-success"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
