import { createBrowserRouter, json } from 'react-router-dom';
import React from 'react';

import NotFound from 'features/errorPages/NotFound';

import { routesWithRoles } from 'constants/routesWithRoles';
import { UserRoleGuard } from 'components/UserRoleGuard';

import { fetchStripeInfo, fetchUserInfo } from 'features/login/LoginMain/network';
import { RoutingStripeGuard } from 'components/RoutingStripeGuard';

export const router = createBrowserRouter([
  {
    path: routesWithRoles.root.path,
    element: routesWithRoles.root.element,
    errorElement: <NotFound />,
    loader: async () => {
      const { success, payload } = await fetchUserInfo();
      const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

      return json({ success, payload, successStripeInfo, payloadStripeInfo });
    }
  },
  {
    path: routesWithRoles.notFound.path,
    element: routesWithRoles.notFound.element
  },
  {
    path: routesWithRoles.signup.path,
    element: routesWithRoles.signup.element
  },
  {
    path: routesWithRoles.register.path,
    element: routesWithRoles.register.element
  },
  {
    path: routesWithRoles.slackAuth.path,
    element: routesWithRoles.slackAuth.element,
    loader: async () => {
      const { success, payload } = await fetchUserInfo();
      const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

      return json({ success, payload, successStripeInfo, payloadStripeInfo });
    }
  },
  {
    path: routesWithRoles.workSpaceRegisterSlackAuth.path,
    element: routesWithRoles.workSpaceRegisterSlackAuth.element
  },
  {
    path: routesWithRoles.forgotPassword.path,
    element: routesWithRoles.forgotPassword.element
  },
  {
    path: routesWithRoles.resetPassword.path,
    element: routesWithRoles.resetPassword.element
  },
  {
    path: routesWithRoles.workspaceRegister.path,
    element: routesWithRoles.workspaceRegister.element
  },
  {
    path: routesWithRoles.activate.path,
    element: routesWithRoles.activate.element
  },
  {
    path: routesWithRoles.usage.path,
    element: routesWithRoles.usage.element,
    loader: async () => {
      const { success, payload } = await fetchUserInfo();
      const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

      return json({ success, payload, successStripeInfo, payloadStripeInfo });
    }
  },
  {
    path: routesWithRoles.logout.path,
    element: routesWithRoles.logout.element
  },
  {
    path: routesWithRoles.layout.path,
    element: routesWithRoles.layout.element,
    loader: async () => {
      const { success, payload } = await fetchUserInfo();
      const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

      return json({ success, payload, successStripeInfo, payloadStripeInfo });
    },
    children: [
      {
        path: routesWithRoles.layout.children.incidents.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.incidents.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.incidents.role}>
              {routesWithRoles.layout.children.incidents.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.apps.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.apps.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.apps.role}>
              {routesWithRoles.layout.children.apps.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.appDetailLayout.children.appDetail.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.appDetailLayout.children.appDetail.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.appDetailLayout.children.appDetail.role}
            >
              {routesWithRoles.layout.children.appDetailLayout.children.appDetail.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.appNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.appNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.appNew.role}>
              {routesWithRoles.layout.children.appNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.appDetailLayout.children.appEdit.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.appDetailLayout.children.appEdit.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.appDetailLayout.children.appEdit.role}
            >
              {routesWithRoles.layout.children.appDetailLayout.children.appEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.logs.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.logs.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.logs.role}>
              {routesWithRoles.layout.children.logs.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.logsEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.logsEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.logsEdit.role}>
              {routesWithRoles.layout.children.logsEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.appDashboard.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.appDashboard.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.appDashboard.role}>
              {routesWithRoles.layout.children.appDashboard.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerSlackList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerSlackList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerSlackList.role}>
              {routesWithRoles.layout.children.triggerSlackList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerSlackNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerSlackNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerSlackNew.role}>
              {routesWithRoles.layout.children.triggerSlackNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerSlackEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerSlackEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerSlackEdit.role}>
              {routesWithRoles.layout.children.triggerSlackEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerLinkList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerLinkList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerLinkList.role}>
              {routesWithRoles.layout.children.triggerLinkList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerLinkNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerLinkNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerLinkNew.role}>
              {routesWithRoles.layout.children.triggerLinkNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerLinkEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerLinkEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerLinkEdit.role}>
              {routesWithRoles.layout.children.triggerLinkEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandList.role}>
              {routesWithRoles.layout.children.commandList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandSshList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSshList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSshList.role}>
              {routesWithRoles.layout.children.commandSshList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandRestList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandRestList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandRestList.role}>
              {routesWithRoles.layout.children.commandRestList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandAwsList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandAwsList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandAwsList.role}>
              {routesWithRoles.layout.children.commandAwsList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandSqlList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSqlList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSqlList.role}>
              {routesWithRoles.layout.children.commandSqlList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.executionCommand.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.executionCommand.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.executionCommand.role}>
              {routesWithRoles.layout.children.executionCommand.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();
          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandSafeList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSafeList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSafeList.role}>
              {routesWithRoles.layout.children.commandSafeList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggers.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggers.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggers.role}>
              {routesWithRoles.layout.children.triggers.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerWebhookList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerWebhookList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerWebhookList.role}>
              {routesWithRoles.layout.children.triggerWebhookList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerWebhookNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerWebhookNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerWebhookNew.role}>
              {routesWithRoles.layout.children.triggerWebhookNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerWebhookEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerWebhookEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerWebhookEdit.role}>
              {routesWithRoles.layout.children.triggerWebhookEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerCronList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerCronList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerCronList.role}>
              {routesWithRoles.layout.children.triggerCronList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerCronNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerCronNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerCronNew.role}>
              {routesWithRoles.layout.children.triggerCronNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.triggerCronEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.triggerCronEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.triggerCronEdit.role}>
              {routesWithRoles.layout.children.triggerCronEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionList.role}>
              {routesWithRoles.layout.children.connectionList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionSshList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSshList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSshList.role}>
              {routesWithRoles.layout.children.connectionSshList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionJiraList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionJiraList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionJiraList.role}>
              {routesWithRoles.layout.children.connectionJiraList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionAwsList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAwsList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAwsList.role}>
              {routesWithRoles.layout.children.connectionAwsList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionSqlList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSqlList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSqlList.role}>
              {routesWithRoles.layout.children.connectionSqlList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionSafeList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSafeList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSafeList.role}>
              {routesWithRoles.layout.children.connectionSafeList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionAgentList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAgentList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAgentList.role}>
              {routesWithRoles.layout.children.connectionAgentList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationList.role}>
              {routesWithRoles.layout.children.integrationList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationNew.role}>
              {routesWithRoles.layout.children.integrationNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationSlackList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationSlackList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationSlackList.role}>
              {routesWithRoles.layout.children.integrationSlackList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationSshList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationSshList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationSshList.role}>
              {routesWithRoles.layout.children.integrationSshList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationWebhookList.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.integrationWebhookList.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationWebhookList.role}>
              {routesWithRoles.layout.children.integrationWebhookList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationEmail.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationEmail.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationEmail.role}>
              {routesWithRoles.layout.children.integrationEmail.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationJira.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationJira.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationJira.role}>
              {routesWithRoles.layout.children.integrationJira.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationStatus.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationStatus.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationStatus.role}>
              {routesWithRoles.layout.children.integrationStatus.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationOpsgenie.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationOpsgenie.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationOpsgenie.role}>
              {routesWithRoles.layout.children.integrationOpsgenie.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationServicedesk.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.integrationServicedesk.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationServicedesk.role}>
              {routesWithRoles.layout.children.integrationServicedesk.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationBitbucket.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationBitbucket.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationBitbucket.role}>
              {routesWithRoles.layout.children.integrationBitbucket.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationAws.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationAws.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationAws.role}>
              {routesWithRoles.layout.children.integrationAws.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationGithub.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationGithub.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationGithub.role}>
              {routesWithRoles.layout.children.integrationGithub.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.integrationPagerduty.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.integrationPagerduty.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.integrationPagerduty.role}>
              {routesWithRoles.layout.children.integrationPagerduty.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretList.role}>
              {routesWithRoles.layout.children.secretList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretSshList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretSshList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSshList.role}>
              {routesWithRoles.layout.children.secretSshList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretRestList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretRestList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretRestList.role}>
              {routesWithRoles.layout.children.secretRestList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretAwsCredentialList.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretAwsCredentialList.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretAwsCredentialList.role}>
              {routesWithRoles.layout.children.secretAwsCredentialList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretSafeList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretSafeList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSafeList.role}>
              {routesWithRoles.layout.children.secretSafeList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretOauthList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretOauthList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretOauthList.role}>
              {routesWithRoles.layout.children.secretOauthList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretCredentialList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretCredentialList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretCredentialList.role}>
              {routesWithRoles.layout.children.secretCredentialList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretSqlCredentialList.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretSqlCredentialList.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSqlCredentialList.role}>
              {routesWithRoles.layout.children.secretSqlCredentialList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.secretJiraCredentialList.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretJiraCredentialList.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.secretJiraCredentialList.role}
            >
              {routesWithRoles.layout.children.secretJiraCredentialList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.settings.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settings.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settings.role}>
              {routesWithRoles.layout.children.settings.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.settingsUser.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsUser.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsUser.role}>
              {routesWithRoles.layout.children.settingsUser.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.settingsGroup.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsGroup.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsGroup.role}>
              {routesWithRoles.layout.children.settingsGroup.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.settingsExecutionPolicy.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.settingsExecutionPolicy.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsExecutionPolicy.role}>
              {routesWithRoles.layout.children.settingsExecutionPolicy.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        //role admin
        path: routesWithRoles.layout.children.settingsApprovalPolicy.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.settingsApprovalPolicy.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsApprovalPolicy.role}>
              {routesWithRoles.layout.children.settingsApprovalPolicy.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsUserNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsUserNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsUserNew.role}>
              {routesWithRoles.layout.children.settingsUserNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsUserEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsUserEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsUserEdit.role}>
              {routesWithRoles.layout.children.settingsUserEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsExecutionPolicyNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.settingsExecutionPolicyNew.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.settingsExecutionPolicyNew.role}
            >
              {routesWithRoles.layout.children.settingsExecutionPolicyNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsExecutionPolicyEdit.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.settingsExecutionPolicyEdit.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.settingsExecutionPolicyEdit.role}
            >
              {routesWithRoles.layout.children.settingsExecutionPolicyEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsGroupNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsGroupNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsGroupNew.role}>
              {routesWithRoles.layout.children.settingsGroupNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsGroupEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.settingsGroupEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.settingsGroupEdit.role}>
              {routesWithRoles.layout.children.settingsGroupEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.settingsEnvironmentVariables.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.settingsEnvironmentVariables.plan}
          >
            <UserRoleGuard
              routeRole={routesWithRoles.layout.children.settingsEnvironmentVariables.role}
            >
              {routesWithRoles.layout.children.settingsEnvironmentVariables.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.newEnvironmentVariable.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.newEnvironmentVariable.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.newEnvironmentVariable.role}>
              {routesWithRoles.layout.children.newEnvironmentVariable.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role admin
        path: routesWithRoles.layout.children.editEnvironmentVariable.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.editEnvironmentVariable.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.editEnvironmentVariable.role}>
              {routesWithRoles.layout.children.editEnvironmentVariable.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretSshNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretSshNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSshNew.role}>
              {routesWithRoles.layout.children.secretSshNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretCredentialNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretCredentialNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretCredentialNew.role}>
              {routesWithRoles.layout.children.secretCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretRestNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretRestNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretRestNew.role}>
              {routesWithRoles.layout.children.secretRestNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretAwsCredentialNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretAwsCredentialNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretAwsCredentialNew.role}>
              {routesWithRoles.layout.children.secretAwsCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretBearerTokenNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.secretBearerTokenNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretBearerTokenNew.role}>
              {routesWithRoles.layout.children.secretBearerTokenNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretSshCredentialNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretSshCredentialNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSshCredentialNew.role}>
              {routesWithRoles.layout.children.secretSshCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretSqlCredentialNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretSqlCredentialNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretSqlCredentialNew.role}>
              {routesWithRoles.layout.children.secretSqlCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretJiraCredentialNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretJiraCredentialNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretJiraCredentialNew.role}>
              {routesWithRoles.layout.children.secretJiraCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretRestTokenAuthNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretRestTokenAuthNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretRestTokenAuthNew.role}>
              {routesWithRoles.layout.children.secretRestTokenAuthNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandSshNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSshNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSshNew.role}>
              {routesWithRoles.layout.children.commandSshNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandSshEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSshEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSshEdit.role}>
              {routesWithRoles.layout.children.commandSshEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandScripthNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandScripthNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandScripthNew.role}>
              {routesWithRoles.layout.children.commandScripthNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandScriptEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandScriptEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandScriptEdit.role}>
              {routesWithRoles.layout.children.commandScriptEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandSqlNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSqlNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSqlNew.role}>
              {routesWithRoles.layout.children.commandSqlNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandSqlEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandSqlEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandSqlEdit.role}>
              {routesWithRoles.layout.children.commandSqlEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandRestNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandRestNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandRestNew.role}>
              {routesWithRoles.layout.children.commandRestNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandRestEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandRestEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandRestEdit.role}>
              {routesWithRoles.layout.children.commandRestEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandAwsNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandAwsNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandAwsNew.role}>
              {routesWithRoles.layout.children.commandAwsNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandAwsEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandAwsEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandAwsEdit.role}>
              {routesWithRoles.layout.children.commandAwsEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandJiraNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandJiraNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandJiraNew.role}>
              {routesWithRoles.layout.children.commandJiraNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.commandJiraEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandJiraEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandJiraEdit.role}>
              {routesWithRoles.layout.children.commandJiraEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.commandJiraList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.commandJiraList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.commandJiraList.role}>
              {routesWithRoles.layout.children.commandJiraList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSshNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSshNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSshNew.role}>
              {routesWithRoles.layout.children.connectionSshNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSshEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSshEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSshEdit.role}>
              {routesWithRoles.layout.children.connectionSshEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionRestNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionRestNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionRestNew.role}>
              {routesWithRoles.layout.children.connectionRestNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionRestEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionRestEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionRestEdit.role}>
              {routesWithRoles.layout.children.connectionRestEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionJiraNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionJiraNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionJiraNew.role}>
              {routesWithRoles.layout.children.connectionJiraNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionJiraEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionJiraEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionJiraEdit.role}>
              {routesWithRoles.layout.children.connectionJiraEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionJiraList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionRestList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionRestList.role}>
              {routesWithRoles.layout.children.connectionRestList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSsmNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSsmNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSsmNew.role}>
              {routesWithRoles.layout.children.connectionSsmNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSsmEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSsmEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSsmEdit.role}>
              {routesWithRoles.layout.children.connectionSsmEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.connectionSsmList.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSsmList.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSsmList.role}>
              {routesWithRoles.layout.children.connectionSsmList.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionAwsNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAwsNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAwsNew.role}>
              {routesWithRoles.layout.children.connectionAwsNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionAwsEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAwsEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAwsEdit.role}>
              {routesWithRoles.layout.children.connectionAwsEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSqlNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSqlNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSqlNew.role}>
              {routesWithRoles.layout.children.connectionSqlNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionSqlEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionSqlEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionSqlEdit.role}>
              {routesWithRoles.layout.children.connectionSqlEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionAgentNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAgentNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAgentNew.role}>
              {routesWithRoles.layout.children.connectionAgentNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.connectionAgentEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.connectionAgentEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.connectionAgentEdit.role}>
              {routesWithRoles.layout.children.connectionAgentEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.stripeSuccess.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.stripeSuccess.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.stripeSuccess.role}>
              {routesWithRoles.layout.children.stripeSuccess.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.layout.children.stripeCancel.path,
        element: routesWithRoles.layout.children.stripeCancel.element
      },
      {
        // role operator
        path: routesWithRoles.layout.children.workflows.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.workflows.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.workflows.role}>
              {routesWithRoles.layout.children.workflows.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.workflowNew.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.workflowNew.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.workflowNew.role}>
              {routesWithRoles.layout.children.workflowNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.workflowEdit.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.workflowEdit.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.workflowEdit.role}>
              {routesWithRoles.layout.children.workflowEdit.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.workflowNewLambda.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.workflowNewLambda.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.workflowNewLambda.role}>
              {routesWithRoles.layout.children.workflowNewLambda.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.workflowEditLambda.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.workflowEditLambda.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.workflowEditLambda.role}>
              {routesWithRoles.layout.children.workflowEditLambda.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.eventLogs.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.eventLogs.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.eventLogs.role}>
              {routesWithRoles.layout.children.eventLogs.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretCmsCredentialNew.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretCmsCredentialNew.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretCmsCredentialNew.role}>
              {routesWithRoles.layout.children.secretCmsCredentialNew.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.secretAwsRoleBaseAuth.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.layout.children.secretAwsRoleBaseAuth.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.layout.children.secretAwsRoleBaseAuth.role}>
              {routesWithRoles.layout.children.secretAwsRoleBaseAuth.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.fileStorage.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.fileStorage.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.fileStorage.role}>
              {routesWithRoles.layout.children.fileStorage.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        // role operator
        path: routesWithRoles.layout.children.newFile.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.layout.children.newFile.plan}>
            <UserRoleGuard routeRole={routesWithRoles.layout.children.newFile.role}>
              {routesWithRoles.layout.children.newFile.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      }
    ]
  },
  {
    path: routesWithRoles.profileLayout.path,
    element: routesWithRoles.profileLayout.element,
    loader: async () => {
      const { success, payload } = await fetchUserInfo();
      const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

      return json({ success, payload, successStripeInfo, payloadStripeInfo });
    },
    children: [
      {
        path: routesWithRoles.profileLayout.children.account.path,
        element: (
          <RoutingStripeGuard routePlan={routesWithRoles.profileLayout.children.account.plan}>
            <UserRoleGuard routeRole={routesWithRoles.profileLayout.children.account.role}>
              {routesWithRoles.profileLayout.children.account.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.profileLayout.children.usage.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.profileLayout.children.usage.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.profileLayout.children.usage.role}>
              {routesWithRoles.profileLayout.children.usage.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      },
      {
        path: routesWithRoles.profileLayout.children.WorkspaceSettings.path,
        element: (
          <RoutingStripeGuard
            routePlan={routesWithRoles.profileLayout.children.WorkspaceSettings.plan}
          >
            <UserRoleGuard routeRole={routesWithRoles.profileLayout.children.WorkspaceSettings.role}>
              {routesWithRoles.profileLayout.children.WorkspaceSettings.element}
            </UserRoleGuard>
          </RoutingStripeGuard>
        ),
        loader: async () => {
          const { success, payload } = await fetchUserInfo();
          const { successStripeInfo, payloadStripeInfo } = await fetchStripeInfo();

          return json({ success, payload, successStripeInfo, payloadStripeInfo });
        }
      }
    ]
  }
]);
