import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ScanSearch } from "lucide-react";

const NoPermissionDialog = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <div className="relative z-10">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/75 transition-opacity" />
      {/* Dialog */}
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          {/* Dialog Panel */}
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:min-h-[450px] sm:p-6 flex flex-col justify-center">
            {/* Icon */}
            <div className="mx-auto flex items-center justify-center mb-4 py-4">
              <ScanSearch
                className="h-12 w-12 text-secondary"
                aria-hidden="true"
              />
            </div>

            {/* Content */}
            <div className="text-center">
              <h3 className="text-2xl font-semibold text-gray-900">
                You don't have access to this page
              </h3>
              <p className="mt-2 text-lg text-gray-500 flex flex-col">
                Please contact Opsbeacon support at{" "}
                <Link
                  to="mailto:support@opsbeacon.com"
                  className="text-blue-600"
                >
                  support@opsbeacon.com
                </Link>
              </p>
            </div>

            {/* Footer */}
            <div className="mt-5 sm:mt-6 sm:flex sm:justify-center">
              <Link
                to="mailto:support@opsbeacon.com"
                className="inline-flex w-full justify-center rounded-md bg-secondary px-8 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-colors sm:w-auto"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NoPermissionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NoPermissionDialog;
