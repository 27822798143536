import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import ProgressBar from "../../components/usage/progress-bar";
import NoPermissionDialog from "../../components/usage/none-permission";
import { useUsageData } from "../../hooks/use-usage-data";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";

const Usage = () => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const userState = useSelector((state) => state.userState);
  const loaderData = useLoaderData();
  const { users,auditLogData } = useUsageData(loaderData);

  // Handle data initialization
  useUsageData(loaderData);

  const { INVOCATION_COUNT, USER_COUNT } = {
    INVOCATION_COUNT: auditLogData.dataCount,
    USER_COUNT: users
  };

  const hasNoPermission =
    userState.stripeInfo.status === "canceled" || 
    userState.stripeInfo.subscriptionPlan === "" || userState.stripeInfo.status === 'inactive';

  if (hasNoPermission) {
    return (
      <NoPermissionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    );
  }
  return (
    <div className="form-container">
      <div className="hero-section">
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl font-semibold capitalize">
              {userState.stripeInfo.subscriptionPlan}
            </CardTitle>
            <CardDescription>
              Status: {userState.stripeInfo.status}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ProgressBar label="Invocation Count" currentUsage={INVOCATION_COUNT}   limit="10000" />
            <ProgressBar label="User Count" currentUsage={USER_COUNT} limit="1000" />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Usage;
